import i18n from "@/libs/i18n";

const fields = [
    {
        key: 'name',
        label: i18n.t('name'),
    },
    {
        key: 'name_en',
        label: i18n.t('name_en'),
    },
    {
        key: 'slug',
        label: i18n.t('slug'),
    },
    {
        key: 'status',
        label: i18n.t('status'),
    },
    {
        key: 'action',
        label: i18n.t('action'),
    },
]

export default fields